getWishlistStatus();

function getWishlistStatus() {
    $.get('/wishlist/status', function (response) {
        var data = JSON.parse(response);
        $(".favourites .fav-count").show().html(data.wishlist > 0 ? data.wishlist : 0);
    });
}

var Wishlist = function (button) {
    this._body = $("body");
    this._addButton = button;
    this._addToCartButton = $(".add-to-cart");
    this._addQuantityInput = $(".add-to-wishlist-quantity");
    this.initialize();
};

Wishlist.prototype = {
    _body: null,
    _addButton: null,
    _addQuantityInput: null,
    _changeTimeout: null,
    _changeRequest: null,
    initialize: function () {
        var self = this;
        $.get('/wishlist/wishlist', {}, function (html) {
            self.openWithHTML(html);
        });
    },
    openWithHTML: function (html) {
        var self = this;
        $(".full-wishlist-placeholder").html(html).addClass('show');

        this.addToWishlist(this._addButton);
        this.addToCart(this._addQuantityInput);

        $(".full-wishlist-placeholder").find('.btn-remove').on('click', function () {
            var product = $(this).parents('.product');

            self.change('remove', {
                product_id: product.data('id')
            }, $(this));

            return false;
        });
    },
    addToWishlist: function (button) {
        var self = this;
        button.unbind('click').on('click', function () {
            $.post('/wishlist/add', {
                product_id: $(this).data('id'),
                quantity: self._addQuantityInput.length == 0 ? 1 : self._addQuantityInput.val(),
                list_type: "wishlist"
            }, function (html) {
                $("#addToFavouritesPopup").modal("show");
                var notice = $("#addToFavouritesPopup").find('.wishlist-notice');
                notice.html(html);
                getWishlistStatus();
            });

            return false;
        });
    },
    change: function (action, data, element) {
        var self = this;

        if (self._changeRequest != null) {
            self._changeRequest.abort();
        }
        if (self._changeTimeout != null) {
            clearTimeout(self._changeTimeout);
        }

        self._changeTimeout = setTimeout(function () {
            element.parents('.product').find(".quantity").prepend('<div class="list-loader"></div>');
            $(".full-wishlist-placeholder").find('.wishlist-overlay').show();

            self._changeRequest = $.post('/wishlist/' + action, data, function (html) {
                self.openWithHTML(html);
                getWishlistStatus();
            });
        }, 600);
    },
    getItems: function () {
        $.get('/wishlist/get', {}, function (wishlistItems) {
            return wishlistItems;
        });
    },
    addToCart: function (qty) {
        this._addToCartButton.on('click', function () {
            var options = [];

            var button = $(this);

            $.post('/cart/add', {
                id: $(this).data('id'),
                qty: qty.length == 0 ? 1 : qty.val(),
                options: options,
                mobile: button.hasClass('add-to-cart-mobile')
            }, function (html) {
                if (button.hasClass('add-to-cart-mobile')) {
                    $("#addToCartPopup").html(html).modal();
                } else {
                    self.openWithHTML(html);
                    getStatus();
                }

            });

            return false;
        });
    },
};

var favouritesPage = $(".favourites");
var fullWishlistPlaceholder = favouritesPage.find(".full-wishlist-placeholder");
if (fullWishlistPlaceholder.length > 0) {
    fullWishlistPlaceholder.prepend('<div class="list-loader"></div>');
    new Wishlist($(".add-to-wishlist-button"));
}

var shopCategory = $(".products-list-placeholder");
if (shopCategory.length > 0) {
    var checkExist = setInterval(function () {
        if ($('.product').length) {
            new Wishlist($(".add-to-wishlist-button"))
            clearInterval(checkExist);
        }
    }, 100);
}

var productPage = $(".product-show");
if (productPage.length > 0) {
    new Wishlist($(".add-to-wishlist-button"));
}

var fullCartPlaceholder = $(".full-cart-placeholder");
if (fullCartPlaceholder.length > 0) {
    fullCartPlaceholder.prepend('<div class="list-loader"><div class="inner"><div></div><div></div><div></div></div></div>');
    new Cart(fullCartPlaceholder, 'full');
}
